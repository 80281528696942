@import '../../variables.scss';

.folderImage{
    width: 153px;
    height: 94px;
    cursor: pointer;
}

.folder{
    margin: $margin-1x;
    float: left;
    max-width: 12rem;
}

.folderImageCaption{
    margin-top: $margin-05x;

}