@import '../../variables.scss';

.activeLink{
    text-decoration: underline;
}

.toolbar{
    background-color: #2F2F2F;

}

.noLink{
    text-decoration: none;
   // color: white;
}

.ArtiusText{
    font-size: 3rem;
    text-align: left;
    width: 100%;
    margin-left: 1rem;
    color: $artius-pink;

    &_dot{
        color: $artius-orange;
    }
}


.spacerGap{
    @media (max-width: $screen-mobile) {
    margin-top: 3rem;
    }
}


.globalLoader{
   // position: absolute;
    background-color:rgba(0,0,0,0.7);
    width: 100%;
    height: 100%;
    z-index: 500;
    color: white;
}

.center-element {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .errorMsg{
    bottom: 10px;
    position: absolute;
    left: 50%;
  }



.subBoxHr{
    border: 1px dashed $purple-stroke;
}

.MuiAlert-message {
    color: #FF4040 !important;
}

.caption {
    font-size: $font-size-05x;
    padding: $padding-05x;
}