@import '../../../../variables.scss';

.galleryImage {
    background: linear-gradient(180deg, #363559 0%, #2F2F4E 100%);
    border: 1px solid #5C659D;
    box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: $padding-1x;
    margin: $margin-05x;
    float: left;
}

.spaceBreak{
    clear: both;
}

.galleryimg{
    cursor: pointer;
}

.galerContainer{
    // 
    width: 80%;
}


.bold{
    font-weight: $font-bold;
}