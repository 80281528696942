@import '../../variables.scss';
.loginBox{
    background: linear-gradient(180deg, #363559 0%, #2F2F4E 100%);
border: 1px solid $purple-stroke;
box-shadow: 8px 8px 8px 1px rgba(0, 0, 0, 0.25);
border-radius: 5px;
padding: $padding-1x;
margin: $margin-1x;
max-width: 35rem;
}

.active{
    color: white;
}

.center{
    text-align: center;
}