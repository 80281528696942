//screen dimensions.
$screen-mobile: 600px;

//// COlours
$bg-purple: #23253C;
$purple-secondary: #343356;
$purple-menu: #272742;
//use for box outlines usually.
$purple-stroke: #4A4A80;
//usually used for non important text.
$purple-text: #737FCE;
$warning : #FF4040;
$artius-pink: #DD0DFF;
$artius-orange: #FC9846;
$artius-green: #67F634;

// Gradient Colours.
$artius-pink-gradient:#E30DFD;
$artius-dark-pink-gradient:#8700F9;

$artius-orange-gradient:#FC9D39;
$artius-dark-orange-gradient:#FE72A2;

$artius-purple-gradient:#363559;
$artius-dark-purple-gradient:#2F2F4E;

// font size and weights
$font-size-05x: 0.8rem;
$font-size-1x: 1rem;
$font-size-1-5x: 1.25rem;
$font-size-2x: 1.5rem;
$font-thin: 200;
$font-regular: 400;
$font-bold: 700;

//padding
$padding-1x : 1rem;
$padding-05x : 0.5rem;
$margin-1x : 1rem;
$margin-2x : 2rem;
$margin-05x : 0.5rem;