@import 'variables.scss';

body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: $font-size-1x;
  font-weight: $font-regular;
  background-color: $bg-purple;
  color: $purple-text;
  letter-spacing: 0.00938em;

}

.purpleBox {
  background: linear-gradient(180deg, #363559 0%, #2F2F4E 100%);
  border-radius: 5px;
  padding: $padding-1x;
  margin-bottom: $margin-1x;
}

a{
  color: $purple-text;
  text-decoration: none;
}

.linkPurple{
  cursor: pointer;
  text-decoration: underline;
}

.linkOrange{
  cursor: pointer;
  color: $artius-orange;
  text-decoration: underline;
}

.headerBreacdrumb{
  margin-bottom: $margin-1x;
  font-size: $font-size-2x;
}
.clickable{
  cursor: pointer;
}

.MuiListItemIcon-root{
  color: $purple-text !important;
}
.MuiIconButton-root{
  color: $purple-text !important;
}
.MuiSvgIcon-root{
  color: $purple-text !important;
}

.MuiFormLabel-root{
  color: $purple-text !important;
}

.MuiOutlinedInput-notchedOutline{
  border-color: $purple-stroke !important;
}

.MuiPaper-elevation{
  background-color: $bg-purple !important;
  border: 1px $purple-stroke solid;
  //color: white !important;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.91);
}

.MuiDrawer-paper{
  background-color: #272742 !important;
  border-right: solid 1px $purple-stroke !important;
}

.artiusButtonOutline{
  color: $purple-text !important;
  border: solid 1px $purple-stroke !important;
}

.Mui-disabled{
  opacity: 0.5 !important;
}

.MuiSlider-track{
  background-color: $artius-orange !important;
  border-color: $artius-orange ;
  color: $artius-orange !important;
}

.MuiSlider-thumb{
  background-color: $artius-orange-gradient !important;
}

.MuiSlider-rail{
  color: $artius-orange !important;
}

.MuiToolbar-root{
  background-color: $purple-menu
}
//Theme Colours

.MuiInput-underline{
  border-bottom: $purple-stroke 2px solid;
}

.orangeButton{
  background: linear-gradient(90deg, #FE72A2 0%, #FC9D39 100%) !important;
}

.elemntCenter{
  display: flex;
  justify-content: center;
  align-items: center;
}

.clearfix{
  clear: both;
}

.float-right{
  float: right;
}

.float-left{
  float: left;
}

.MuiDivider-root::after{
  border-top: thin solid $purple-stroke !important;
}

.MuiDivider-root::before{
  border-top: thin solid $purple-stroke !important;
}

.MuiAlert-message{
  color: white !important;
}

::-moz-selection { /* Code for Firefox */
  color: black;
  background: $artius-orange;
}

::selection {
  color: black;
  background: $artius-orange;
}

.MuiDialog-root .MuiDialog-scrollPaper .MuiDialog-scrollBody .MuiDialog-paperScrollPaper .MuiDialog-container {
  transition-timing-function: ease;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
  -webkit-transition: 1s;
  transition: 1s;
}

.Mui-selected svg {
  color: $artius-orange !important;
}

.MuiFormHelperText-root .MuiFormHelperText-sizeMedium .MuiFormHelperText-contained {
  color: red !important;
}

.caption{
  font-size: $font-size-05x;
}

a.active svg{
  // for selected links
  color: white !important;
}