@import '../../variables.scss';

.loginHeader {
    font-weight: $font-bold;

    font-size: xx-large;
    color: #FFFFFF;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
    margin-left: $margin-1x;
}

.loginContainer {
    max-width: 35rem;
}

.margin-top-displace {
    padding-top: 10rem;
}

.promntBox {
    background: #23253C;
    border: 1px solid #636363;
    border-radius: 5px;
    width: 88%;
    color: white;
    font-size: $font-size-2x;
    padding: $padding-1x;
    margin: $margin-1x;
}

.BG{
    height: 100vh;
    background-image: url('../../assets/bg.jpg');
    margin: -24px;
}