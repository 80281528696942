@import '../../../../variables.scss';

.lineBox {
    width: 13rem;
    position: absolute;
    top: 4.1rem;
    right: 1rem;
    border: 1px solid $purple-stroke;
    border-radius: 5px;
    max-height: 80vh;
    background-color: $bg-purple;
    padding: $padding-1x;
    padding-top: 0px;
    display:auto;
    overflow-x: auto;
    z-index: 500;
    &__close{
        //when linebox is closed, mostly used in mobile
        color: red;
        width: 2rem;
        overflow: hidden;
        top: 50vh;
        padding: 0rem;
        padding: 0.2rem;
        padding-right: 0.5rem;
    }
}

.settingHeader {
    color: white;
    font-weight: $font-bold;
}

.spacer{
    margin-bottom: $margin-1x;
}

.lineBox::-webkit-scrollbar {
    width: 0.5rem;
}

.lineBox::-webkit-scrollbar-track {
    background-color: $purple-secondary;
  //  border-radius: 100px;
}

.lineBox::-webkit-scrollbar-thumb {
    border-radius: 100px;
/*     border: 5px solid transparent;
    background-clip: content-box; */
    background-color: $purple-stroke;
}

.helperText {
    color: $purple-text !important;
  }