@import '../../variables.scss';

.settingHeader {
    color: white;
    font-weight: $font-bold;
    width: 100%;
    margin-bottom: $margin-1x;
    margin-top: $margin-1x;
    cursor: pointer;
}

.content{
   // overflow: overlay;
    overflow-x: hidden;
    overflow-y: hidden;
    transition-timing-function: ease;

    -moz-transition: 0.2s ease-in-out;
    -ms-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
}

.arrowIconStyle{
    margin-bottom: -0.5rem;
    transform: rotateZ(360deg);

    -moz-transition: 0.2s ease-in-out;
    -ms-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
}

.content::-webkit-scrollbar {
    width: 0.5rem;
}

.content::-webkit-scrollbar-track {
    background-color: $purple-secondary;
  //  border-radius: 100px;
}

.content::-webkit-scrollbar-thumb {
    border-radius: 100px;
/*     border: 5px solid transparent;
    background-clip: content-box; */
    background-color: $purple-stroke;
}

