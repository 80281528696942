@import '../../../variables.scss';



.settings{
    float: right;
}

.subTitle{
    width: 70%;
    font-size: $font-size-1x;
    @media (max-width: $screen-mobile) {
        width: 100%;
    }
}

.sucess{
    background: rgba(103, 246, 52, 0.1);
border: 1px solid #67F634;
border-radius: 5px;
width: 60%;
color: white;
padding: $padding-1x;
}

.titleLink{
    cursor: pointer;
}

.completed {
    background: rgba(52, 176, 246, 0.1);
    border: 1px solid #34F6C7;
    border-radius: 5px;
    width: 60%;
    color: white;
    padding: $padding-1x;
}

.failed {
    background: rgba(246, 52, 52, 0.1);
    border: 1px solid #F63434;
    border-radius: 5px;
    width: 60%;
    color: white;
    padding: $padding-1x;
}
